<template>
  <v-dialog v-model="dialog" persistent max-width="420">
    <template v-if="isAnyId" v-slot:activator="{ on, attrs }">
      <button v-bind="attrs" v-on="on" class="btn btn-delete btn_color_red">
        удалить {{ name }}
      </button>
    </template>
    <v-card>
      <v-card-title class="delete-title delete-center">
        <div>Удалить</div>
        <span class="delete-bold"> "{{ title }}" </span>
      </v-card-title>
      <v-card-text v-if="isDescription" class="delete-center">
        {{ description }}
      </v-card-text>
      <v-card-subtitle>
        <v-radio-group v-model="selectBlock">
          <v-radio
            v-if="realDillerOrganizationId && checkDeletePermission('diller')"
            :value="1"
            :label="'Дилер - ' + diller.name"
            >{{
          }}</v-radio>
          <v-radio
            v-if="realOrganizationId && checkDeletePermission('organization')"
            :value="2"
            :label="'Компания - ' + organization.name"
          ></v-radio>
          <v-radio
            v-if="realKindergartenId && checkDeletePermission('kindergarten')"
            :value="3"
            :label="'Детский сад - ' + organization.name"
          ></v-radio>
          <v-radio
            v-if="realObjectId && checkDeletePermission('object')"
            :value="4"
            :label="'Объект - ' + object.name"
          ></v-radio>
          <v-radio
            v-if="realGroupId && checkDeletePermission('groups')"
            :value="5"
            :label="'Группа - ' + group.title"
          ></v-radio>
        </v-radio-group>
      </v-card-subtitle>
      <v-card-actions>
        <v-btn
          width="154"
          height="42"
          class="ma-5 btn btn_color_white"
          text
          @click="close"
        >
          Отменить
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          width="154"
          height="42"
          :style="{ opacity: !selectBlock ? '33%' : 'unset' }"
          color="#EB5C6D"
          class="ma-5 btn btn_color_red"
          style="color:white"
          @click="realDelete"
        >
          Удалить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { getAccessGroupByIdRequest } from "@/helpers/api/access-group";
import { getObjectByIdRequest } from "@/helpers/api/objects";
import { getOrganizationsByIdRequest } from "@/helpers/api/organizations";
import { getKindergartenByIdClearRequest } from "@/helpers/api/kindergartens";
import {
  deleteArrCardRequest,
  getkindergartensCardsRequest
} from "@/helpers/api/cards";
import Axios from "axios";
import router from "@/router";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";

export default {
  name: "DeleteUserPopup",
  data() {
    return {
      dialog: false,
      group: {},
      object: {},
      organization: {},
      diller: {},
      selectBlock: null
    };
  },
  props: {
    name: {
      type: [String, Number]
    },
    title: {
      type: String
    },
    userId: {
      type: [Number, String]
    },
    groupId: {
      type: [Number, String]
    },
    objectId: {
      type: [Number, String]
    },
    organizationId: {
      type: [Number, String]
    },
    dillerOrganizationId: {
      type: [Number, String]
    },
    kindergartenId: {
      type: [Number, String]
    },
    isDescription: {
      type: Boolean,
      default: true
    },
    description: {
      type: String
    },
    deletePermission: {
      type: Object
    }
  },
  created() {
    if (this.realGroupId) {
      getAccessGroupByIdRequest({ id: this.realGroupId }).then(res => {
        this.group = res.data;
      });
    }
    if (this.realObjectId) {
      getObjectByIdRequest({ id: this.realObjectId }).then(res => {
        this.object = res.data;
      });
    }
    if (this.realOrganizationId) {
      getOrganizationsByIdRequest({ id: this.realOrganizationId }).then(res => {
        this.organization = res.data;
      });
    }
    if (this.realDillerOrganizationId) {
      getOrganizationsByIdRequest({ id: this.realDillerOrganizationId }).then(
        res => {
          this.diller = res.data;
        }
      );
    }
    if (this.realKindergartenId) {
      getOrganizationsByIdRequest({ id: this.realKindergartenId }).then(res => {
        this.organization = res.data;
      });
    }
  },
  computed: {
    realGroupId: {
      get() {
        return this.groupId;
      }
    },
    realObjectId: {
      get() {
        return this.objectId;
      }
    },
    realOrganizationId: {
      get() {
        return this.$store.getters.getCurrentRole === 30
          ? this.$store.getters.getCurrentOrganization
          : this.organizationId;
      }
    },
    realDillerOrganizationId: {
      get() {
        return [60].includes(this.$store.getters.getCurrentRole)
          ? this.$store.getters.getCurrentOrganization
          : this.dillerOrganizationId;
      }
    },
    realKindergartenId: {
      get() {
        return this.$store.getters.getCurrentRole === 31
          ? this.$store.getters.getCurrentOrganization
          : this.kindergartenId;
      }
    },
    isAnyId() {
      return (
        this.realDillerOrganizationId ||
        this.realGroupId ||
        this.realKindergartenId ||
        this.realOrganizationId ||
        this.realObjectId
      );
    }
  },
  methods: {
    checkDeletePermission(elem) {
      if (this.deletePermission) {
        return this.deletePermission.from.includes(elem) ? true : false;
      } else {
        return true;
      }
    },
    close() {
      this.dialog = false;
    },
    realDelete() {
      if (this.selectBlock) {
        this.deleteUser();
      }
      this.close();
    },
    async deleteUser() {
      switch (this.selectBlock) {
        case 1: {
          await Axios.post(`/users/${this.userId}/exclude_from_diller/`, {
            organization: this.realDillerOrganizationId
          });
          router.go(-1);
          break;
        }
        case 2: {
          await Axios.post(`/users/${this.userId}/exclude_from_organization/`, {
            organization: this.realOrganizationId,
            id: this.userId
          });
          router.go(-1);
          break;
        }
        case 3: {
          const self = this;

          const kinderObjectId = async () =>
            (
              await getKindergartenByIdClearRequest({
                id: this.realKindergartenId
              }).then(
                this.$store.dispatch(
                  "successNotification",
                  actionMessage.remove(successMessageDictionary.user)
                )
              )
            ).data.object.id;
          kinderObjectId()
            .then(async () => {
              const currentUserCard = [];

              await getkindergartensCardsRequest({
                query: {
                  search:
                    self.$store.getters.getCreateCurrentUserKindergartens
                      .phone_number
                },
                id:
                  localStorage.getItem("organization") != 1
                    ? localStorage.getItem("organization")
                    : localStorage.getItem("KindergartenId")
              }).then(response => currentUserCard.push(response.data.results));

              const arrCards = currentUserCard[0].reduce(function(accum, elem) {
                accum.push(elem.id);
                return accum;
              }, []);
              return arrCards;
            })
            .then(async arrCards => {
              await Axios.post(
                `/users/${self.userId}/exclude_from_organization/`,
                {
                  // удаляем пользователя из ДС
                  id: self.userId,
                  organization: self.realKindergartenId
                }
              );
              if (arrCards.lenght > 0) {
                await deleteArrCardRequest({
                  data: { cards_to_delete: arrCards }
                }); // Удаляем карты пользователя, которые привязаны к объекту
              }
              router.go(-1);
            });
          break;
        }
        case 4: {
          const self = this;

          const arrCards = self.$store.getters.getUserInfo.results.cards.reduce(
            function(accum, elem) {
              if (elem.object === self.realObjectId) {
                accum.push(elem.id);
              }
              return accum;
            },
            []
          );

          //удаляем пользователя из объекта
          await Axios.post(`/users/${this.userId}/exclude_from_object/`, {
            object: self.realObjectId
          });
          if (arrCards.lenght > 0) {
            await deleteArrCardRequest({
              data: { cards_to_delete: arrCards }
            }); // Удаляем карты пользователя, которые привязаны к объекту
          }
          router.go(-1);
          break;
        }
        case 5: {
          await Axios.post(`/users/${this.userId}/exclude_from_group/`, {
            group: this.realGroupId
          });
          router.go(-1);
          break;
        }
      }
    }
  }
};
</script>

<style scoped>
.delete-title {
  padding-top: 30px !important;
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 4px !important;
  /* or 143% */

  text-align: center !important;

  color: #515a6e;
}
.delete-bold {
  font-weight: 700 !important;
}

.delete-center {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 12px;
}
</style>
